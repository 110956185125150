.carousel-item-padding-40-px {
    margin: 0px 5px;
}


/* Slider arrow color */
.react-multiple-carousel__arrow{
    background: rgba(187,187,187,0.5);
    min-width: 35px;
    min-height: 35px;
}

.react-multiple-carousel__arrow--right {
    right: calc(1% + 1px);
}

.react-multiple-carousel__arrow--left {
    left: calc(1% + 1px);
}

.react-multiple-carousel__arrow:hover{
    background: rgba(55,53,53,0.5);
}

.react-multi-carousel-track {
    padding: 0.5rem;
}

.react-multi-carousel-list{
    position: unset !important;
}
