body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hide-div{
  display: none;
}
.qrcode-desc{
  font-size: 15 px;
  color: #999;
  margin-top: 10px;
  /* width: 30%; */
  display: block;
  font-weight: 400;
  
}
.pull-left{
  float: left;
}
.pull-right{
  float: right;
}
.center{
  text-align: center;
  float:none;
}